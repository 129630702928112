import $ from "jquery";

$(function() {
    let sectionIdx = 2;
    /*
    $(".sectionbg-auto, .sectionbg-1, .sectionbg-2").each(function() {
        const $section = $(this);
        if($section.hasClass("sectionbg-auto")) {
            $section.addClass("sectionbg-"+sectionIdx);
            $section.removeClass("sectionbg-auto");
        } else if($section.hasClass("sectionbg-1")) {
            sectionIdx = 1;
        } else if($section.hasClass("sectionbg-2")) {
            sectionIdx = 2;
        }
        
        sectionIdx++;
        if(sectionIdx == 3) {
            sectionIdx = 1;
        }
    });
    */

    //Automatic alternating background
    $(".sectionbg-auto").each(function() {
        const $section = $(this);
        $section.addClass("sectionbg-"+sectionIdx);
        $section.removeClass("sectionbg-auto");
        
        sectionIdx++;
        if(sectionIdx == 3) {
            sectionIdx = 1;
        }
    });
});

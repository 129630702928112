import $ from "jquery";

$(function() {
    const $footer = $("footer");
    const $html = $("html");
    
    function updateFooterHeight() {
        const footerHeight = $footer.outerHeight();
        $html.css("--footerheight", footerHeight+"px");
    }
    
    updateFooterHeight();
    $(window).on("load", updateFooterHeight);
    setInterval(updateFooterHeight, 2000);
});
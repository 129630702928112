import $ from "jquery";

$(function() {
    $(".bl-hero .icon-heroscrolldownarrow").on("click", function() {
        const $scrolldownindicator = $(this);
        const $hero = $scrolldownindicator.closest("section");
        const $nextElement = $hero.next().find(".cblock").first();
        $(window).scrollTo($nextElement, 500);
    });
});
